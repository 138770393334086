import { GET_CONTENT_LIBRARY_DATA, GET_CONTENT_LIBRARY_DATA_SUCCESS } from "../actions/actionTypes";

  
  const initialState = {
    contentLibraryData:null,
  };
  export const contentLibraryReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_CONTENT_LIBRARY_DATA_SUCCESS:
        return { ...state, contentLibraryData:action.payload};
    
      default:
        return state;
    }
  };