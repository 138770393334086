import { createStore, combineReducers,applyMiddleware  } from "redux";
import createSagaMiddleware from 'redux-saga'

import { contentLibraryReducer } from "../containers/ContentLibrary/reducers/contentLibrary";
import { watcherSaga } from "../redux/rootSaga";

const sagaMiddleware = createSagaMiddleware()
const reducer=combineReducers({
    contentLibrary:contentLibraryReducer
})
const store=createStore(reducer,applyMiddleware(sagaMiddleware))
sagaMiddleware.run(watcherSaga)
export default store