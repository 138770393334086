import React from "react";

export const urls = {
  login: `/Login/Index`,
  //-------------start of clever sso code -------------
  getAccessToken: `/ssoLogin/getAccessCode`,
  getSSOSource: `/ssoLogin/IsCleverSSOEnable`,
  getDistricts: `/Login/getAllDistricts`,
  cleverssoLogin: `/cleverlogin/login`,
  //-------------end of clever sso code -------------
  getUserInfo: `/Login/getUserinfo`,
  refreshToken: `/Login/Extend`,
  retrievePassword: `/Login/RetrievePassword`,
  validateEmail: `/Login/ValidateEmail`,
  generateResetLink: `/Login/GenerateResetLink`,
  validateResetLink: `/Login/ValidateResetLink`,
  resetPassword: `/Login/ResetPassword`,
  ExternalToolSetUp: {
    updateUserDetail: `/ExternalToolSetUp/update_user_detail`,
  },
  validateOnerosterfiles: `/ExternalUserImport/validate_onerosterfiles`,
  importUsersOneroaster: `/ExternalUserImport/import_users_oneroaster`,
  validateUserfiles: `/ExternalUserImport/validate_userfiles`,
  importOnerosterUsers: `/ExternalUserImport/import_oneroster_users`,
  validateEnrollmentfiles: `/ExternalUserImport/validate_enrollmentfiles`,
  importOnerosterEnrollments: `/ExternalUserImport/import_oneroster_enrollments`,
  checkOnerosterMygroupsStatus: `/ExternalUserImport/check_oneroster_mygroups_status`,
  checkOnerosterUsersStatus: `/ExternalUserImport/check_oneroster_users_status`,

  content: {
    getItems: `/content/get_items`,
    getUnits: `/content/get_units`,
    addItemsToMyLibrary: `/content/add_to_mylibrary`,
    removeItemFromMylibrary: `/content/remove_from_mylibrary`,
    removeExternalItemFromMylibrary: `/content/remove_external_resource`,
    addExternalItems: `/content/add_external_items`,
    updateExternalItems: `/content/update_external_items`,
    trackContentStart: `/content/start_content`,
    trackContentEnd: `/content/end_content`,
    getContentUrlId: `/Content/get_content_url_id`,
    getContent: `/content/get_content_from_contenturl`,
    addExternalAttachment: `/content/add_external_items_localfile`,
    getAllUnits: `/content/get_allUnits`,
    getAllUnitsForReader: `/reader/get_allUnitsForReader`,
  },
  SecureSync: {
    getAllDistrictList: `/SecureSync/getDistrictInfo`,
    insertDataManually: `/SecureSync/insertManualData`,
    updateDataManually: `/SecureSync/UpdateManualData`,
    deleteDataManually: `/SecureSync/DeleteManualData`,
    addNewCleverDistrict: `/SecureSync/insertExternalDistrict`,
    updateExistingCleverDistrict: `/SecureSync/updateExternalDistrict`,
  },
  //-------------start of clever sso code -------------
  SecureSync: {
    InsertSecureSyncInfo: `/SecureSync/IsSecureSyncInserted`,
    UpdateSecureSyncInfo: `/SecureSync/IsSecureSyncUpdated`,
    DeleteSecureSyncInfo: `/SecureSync/IsSecureSyncDeleted`,

    getAllDistrictList: `/SecureSync/getDistrictInfo`,
    insertDataManually: `/SecureSync/insertManualData`,
    updateDataManually: `/SecureSync/UpdateManualData`,
    deleteDataManually: `/SecureSync/DeleteManualData`,
    addNewCleverDistrict: `/SecureSync/insertExternalDistrict`,
    updateExistingCleverDistrict: `/SecureSync/updateExternalDistrict`,
    insertDistrictData: `/SecureSync/insert_district_data`,
    statusOfCleverSecureSync: `/SecureSync/GetAllDistrictAutoimationInfo`,
  },
  //-------------end of clever sso code -------------
  sslogin: {
    ssoLogin: `/Login/Login_as_user`,
  },
  glossary: {
    getGlossary: `/GLossary/get_glossary`,
    startGlossary: `/GLossary/start_glossary`,
    saveGlossary: `/GLossary/save_glossary`,
  },
  dashboard: {
    teacherDashboardData: `/Dashboard/load_teacher_dashboard`,
    studentDashboard: `/Dashboard/load_student_dashboard`,
    getAssignmentResponses: `/Dashboard/get_assignment_responses`,
  },
  assessment: {
    assessment_overview: `/assessment/assessment_overview`,
    start_assessment: `/assessment/start_assessment`,
    submit_assessment: `/assessment/submit_assessment_response`,
    getClasses: `/lesson/get_classes`,
    getClassesAndUsers: `/lesson/get_classes_and_users`,
    assignAssessment: `/assessment/assign_assessment`,
  },
  workspace: {
    lesson_player: `/Assignment/lesson_player`,
    track_lesson: `/Assignment/track_lesson`,
    track_lesson_resource: `/Assignment/track_lesson_resource`,
    quiz_overview: `/quiz/quiz_overview`,
    quiz_details: `/quiz/get_quiz_details`,
    StartQuiz: `/quiz/start_quiz`,
    submitQuiz: `/quiz/submit_quiz_response`,
    workspaceData: `/workspace/Index`,
    getAllLessons: `/workspace/GetAllLessons`,
  },

  lesson: {
    createLesson: `/lesson/create_lesson`,
    getLesson: `/lesson/get_lesson`,
    updateLesson: `/lesson/update_lesson`,
    deleteLesson: `/lesson/delete_lesson`,
    assignAssignment: `/Assignment/assign_assignment`,
    startLesson: `/lesson/start_lesson`,
    previewLesson: `/lesson/preview_lesson`,
    duplicateLesson: `/Assignment/duplicate_lesson`,
    duplicatePrebuilt: `/lesson/duplicate_prebuilt`,
    getLessonURLId: `/lesson/get_lesson_url_id`,
    createLessonURLId: `/lesson/create_lesson_url_id`,
    getSharedUsersForLesson: `/Lesson/get_shared_users_for_lesson`,
    shareLesson: `/lesson/share_lesson`,
    shareLessonFromURL: `/lesson/share_lesson_from_lessonUrl`,
    assignAssignmentFromURL: `/Assignment/assign_assignment_from_lessonUrl`,
    getClasses: `/lesson/get_classes`,
    assignMultipleClasses: `/lesson/assign_multiple_classes`,
    unAssign: `/Assignment/unassign_assignments`,
    checkResourceCompletion: `/lesson/check_resource_completion`,
  },
  folder: {
    createFolder: `/Folder/create_folder`,
    updateFolder: `/Folder/update_folder`,
  },
  mygroups: {
    mygroupsData: `/MyGroup/get_mygroupslist_and_details`,
    mygroupsAdd: `/MyGroup/create_mygroup`,
    mygroupsDelete: `/MyGroup/delete_mygroup`,
    mygroupsUpdate: `/MyGroup/update_mygroup`,
    mygroupsSubgroup: `/MyGroup/load_pagedata_mysubgroup`,
    mygroupsCreateData: `/MyGroup/load_pagedata_mygroup`,
    mygroupsDetails: `/MyGroup/get_mygroup_details`,
    getGroupUsersLabel: `/MyGroup/get_group_users_labels`,
    updateGroupName: `/MyGroup/update_group_name`,
    removeUsersFromGroup: `/MyGroup/remove_users_from_group`,
    getClasses: `/MyGroup/get_classes`,
    getClassesAndUsers: `/lesson/get_classes_and_users`,
  },
  admin: {
    getItems: `/content/get_items`,
    getAllLanguages: `/language/get_all_languages`,
    getAllItems: `/item/get_all_items`,
    getItemList: `/item/get_item`,
    getPrintDocumentList: `/item/get_all_item_printdocuments`,
    createItemPrintdocuments: `/item/create_item_printdocuments`,
    updatePrintDocument: `/item/update_item_printdocuments`,
    getSingleItem: `/item/get_item`,
    updateItem: `/item/update_item`,
    createItem: `/item/create_item`,
    getItemTypes: `/product/get_item_types`,
    getGrades: `/grade/get_all_grades`,
    getGradesForAdminUsageReport: `/grade/getGrades`,
    createGrade: `/grade/create_grade`,
    updateGrade: `/grade/update_grade`,
    getReaders: `/reader/get_readers`,
    createReader: `/reader/create_reader`,
    updateReader: `/reader/update_reader`,
    updateReaderPrintdocuments: `/reader/update_reader_printdocuments`,
    addReaderPrintdocuments: `/reader/add_reader_printdocuments`,
    removeReaderPrintdocuments: `/reader/remove_reader_printdocuments`,
    removeReader: `/reader/remove_reader`,
    getBoards: `/board/get_all_boards`,
    getSubjects: `/subject/get_all_subjects`,
    getLanguages: `/language/get_all_languages`,
    getProducts: `/product/get_all_products`,
    createProduct: `/product/create_product`,
    updateProduct: `/product/update_product`,
    duplicateProduct: `/product/duplicate_product`,
    getAllUnits: `/unit/get_all_units`,
    createUnit: `/unit/create_unit`,
    updateUnit: `/unit/update_unit`,
    getAllSubUnits: `/subUnit/get_all_sub_units`,
    createSubUnit: `/subUnit/create_sub_unit`,
    updateSubUnit: `/subUnit/update_sub_unit`,
    getAllLevel3: `/level3/get_all_level3_`,
    createLevel3: `/level3/create_level3`,
    updateLevel3: `/level3/update_level3`,
    getQuizQuestionsAns: `/quizQuestions/get_quiz_questionsAns`,
    createQZQuesAns: `/quizQuestions/create_QZQues_Ans`,
    updateQZQuesAns: `/quizQuestions/update_QZQues_Ans`,
    getProductPermission: `/product/get_existing_product_permission`,
    assignProductPermission: `/product/assign_product_permission`,
    removeExistingProductPermission: `/product/remove_existing_product_permission`,
    getStandard: `/standards/get_standard`,
    createStandard: `/standards/create_standard`,
    updateStandard: `/standards/update_Standard`,
    removeStandard: `/standards/remove_Standard`,
    getAssessment: `/assessment/get_assessment`,
    getQuestionBank: `/assessment/get_questionBank`,
    createAssessment: `/assessment/create_assessment`,
    updateAssessment: `/assessment/update_assessment`,
    duplicateAssessment: `/assessment/duplicate_assessment`,
    getAssessmenttype: `/assessment/get_assessmenttype`,
    getAssessmentQuestionsAns: `/assessment/get_assessment_questionsAns`,
    createASMQuesAns: `/assessment/create_ASMQues_Ans`,
    updateASMQuesAns: `/assessment/update_ASMQues_Ans`,
    getPrebuiltAssignments: `/prebuiltAssignment/get_Prebuilt_Assignments`,
    createPrebuiltAssignments: `/prebuiltAssignment/create_Prebuilt_Assignments`,
    updatePrebuiltAssignments: `/prebuiltAssignment/update_Prebuilt_Assignments`,
    updatePrebuiltAssignmentType: `/prebuiltAssignment/update_Prebuilt_AssignmentType`,
    getPrebuiltAssignmentsResources: `/prebuiltAssignment/get_Prebuilt_AssignmentsResources`,
    createPrebuiltAssignmentsResources: `/prebuiltAssignment/create_Prebuilt_AssignmentsResources`,
    updatePrebuiltAssignmentsResources: `/prebuiltAssignment/update_Prebuilt_AssignmentsResources`,
    archiveDistrictUsers: `/ContentSetup/archive_districtuser`,
    archiveSchoolUsers: `/ContentSetup/archive_schooluser`,
    promoteDistrictUsers: `/ContentSetup/promote_districtstudent`,
    promoteSchoolUsers: `/ContentSetup/promote_schoolstudent`,
    savePrebuiltAssignmentPermission: `/prebuiltAssignment/save_prebuilt_assignment_permission`,
    exportUsers: `/ExportUsers/export_users`,
    exportActiveUsers: `/ExportUsers/export_active_users`,
    modulePermission: {
      getModulesBySelection: `/ModulePermission/get_modules_by_selection`,
      getModules: `/ModulePermission/get_modules`,
      saveModulePermission: `/ModulePermission/save_module_permissions`,

      saveAdminModulePermission: `/modulePermission/save_admin_module_permissions`, //new user type
      get_admin_modules: `/modulePermission/get_admin_modules`, //new user type
      get_admin_module_permissions: `/modulePermission/get_admin_module_permissions`, //new user type
    },
    statusOfOneRosterAutoamtion: `/oneRosterAutomation/StatusOfOneRosterAutoamtion`,
    ETLReport: {
      getUsageSummary: `/ETLReport/get_usage_summary`,
      get_usage_detail: `/ETLReport/get_usage_detail`,
      getAssignmentSummary: `/ETLReport/get_assignment_summary`,
      getAssignmentDetail: `/ETLReport/get_assignment_detail`,
      exportQumulativeLoginReportCsv: `/ETLReport/export_login_report_csv`,
      exportAssignmentReportCsv: `/ETLReport/export_admin_assignment_report_csv`,
      exportContentReportCsv: `/ETLReport/export_usage_report_csv`,
      supportUsageReportCsv: `/ETLReport/support_usage_report_csv`,
      getETLGrades: `/ETLReport/get_grades`,
      getETLQuizzes: `/ETLReport/get_quizzes`,
      getItemAnalysisDistrict: `/ETLReport/get_itemanalysis_district`,
      exportQumulativeContentReportCsv: `/ETLReport/export_usage_report_csv`,
      exportSplittedLoginReportCsv: `/ETLReport/export_loginreport_grade_csv`,
      exportSpittedContentReportCsv: `/ETLReport/export_usagereport_grade_csv`,
      getGroupGrades: `/ETLReport/get_group_grades`,
      getAttempts: `/ETLReport/get_attempts`,
      getItemAnalysisState: `/ETLReport/get_itemanalysis_state`,
    },
    user: {
      getAllDistricts: `/district/get_all_districts`,
      getAllSchools: `/school/get_all_schools`,
      getAdminUserList: `/UserSetup/get_district_admin_for_district`,
      getUserList: `/user/get_all_users`,
      getUser: `/user/get_user`,
      searchUser: `/user/search_users`,
      getRoles: `/roles/get_all_roles`,
      createRoles: `/roles/create_role`,
      updateRoles: `/roles/update_role`,
      getDistrictAdminForDistrict: `/UserSetup/get_district_admin_for_district`,
      addUser: `/user/create_user_form`,
      removeUser: `/user/remove_user`,
      addSchool: `/school/create_school`,
      updateSchool: `/school/update_school`,
      addDistrict: `/district/create_district`,
      updateDistrict: `/district/update_district`,
      getCurrentPassword: `/UserSetup/get_current_password`,
      updateUser: `/user/update_user_form`,
      getAllUserPermission: `/UserSetup/get_all_users_permission`,
      setExpiry: `/district/set_user_expiry`,
      loginAsUser: `/UserSetup/Login_as_user`,
      resetPassword: `/UserSetup/reset_password`,
      getProductsAssigned: `/user/get_products_assigned`,
      getAllLtiComsumerList: `/LTI/get_all_lti_comsumer_list`,
      getAllSamlComsumerList: `/SAMLConsumer/get_all_saml_comsumer_list`,
      addNewLTIConsumer: `/LTI/add_new_lti_consumer`,
      updateExistingLtiConsumer: `/LTI/update_existing_lti_consumer`,
      addNewSamlConsumer: `/SAMLConsumer/add_new_saml_consumer`,
      updateExistingSamlConsumer: `/SAMLConsumer/update_existing_saml_consumer`,
      addNewOnerosterConsumer: `/UserSetup/add_new_oneroster_consumer`,
      updateExistingOnerosterConsumer: `/UserSetup/update_existing_oneroster_consumer`,
      getAllOnerosterComsumerList: `/UserSetup/get_all_oneroster_comsumer_list`,
      getAllStates: `/UserSetup/get_all_states`,
      getRosterDataForDistrict: `/UserSetup/get_roster_data_for_district`,
      getUserInfo: `/UserSetup/get_user_info`,
      updateUserInfo: `/UserSetup/update_user_info`,
      getAllOnerosterSchoolmapping: `/UserSetup/get_all_oneroster_schoolmapping`,
      addNewOnerosterSchoolmapping: `/UserSetup/add_new_oneroster_schoolmapping`,
      updateOnerosterSchoolmapping: `/UserSetup/update_oneroster_schoolmapping`,
      validateConsumerData: `/UserSetup/validate_consumer_data`,
      transferMyGroups: `/userImport/transfer_mygroups`,
      checkRestapiData: `/UserSetup/check_restapi_data`,
      checkRestapiStatus: `/UserSetup/check_restapi_status`,
      getRosterDataforDistrictAsync: `/UserSetup/get_roster_data_for_district_async`,
      sendFeedback: `/UserSetup/feedback_insert`,
    },
    requestUSerUpload: {
      requestUSerUpload: `/RequestUserUpload/request_user_upload`,
    },
    userImport: {
      validateImport: `/UserImport/import_file`,
      importUsers: `/UserImport/import_users`,
      validateResetUserId: `/userImport/reset_userid_validate`,
      resetUserid: `/userImport/reset_userid`,
      batchActivate: `/userImport/batch_activate`,
      ValidateBatchActivate: `/userImport/batch_activate_file_upload`,
      ValidateImportMygroup: `/userImport/import_mygroup_file`,
      importMygroup: `/userImport/import_mygroups`,
      getSchoolYears: `/UserImport/get_school_years`,
      validateResetPassword: `/userImport/reset_password_validate`,
      batchResetPassword: `/userImport/reset_password`,
    },
    adminReport: {
      usageReportMonthly: `/AdminReport/usage_report_monthly`,
      usageReportWeekly: `/AdminReport/usage_report_weekly`,
      usageReportDaily: `/AdminReport/usage_report_daily`,
      reportUserLogin: `/AdminReport/report_user_login`,
      getLoginLaunchesMonthly: `/AdminReport/get_logins_launches_monthly`,
      getLoginLaunchesWeekly: `/AdminReport/get_logins_launches_weekly`,
      getLoginLaunchesDaily: `/AdminReport/get_logins_launches_daily`,
    },
  },

  reports: {
    getStudentViewSummary: `/Report/get_student_view_summary`,
    getStudentQuizResponse: `/Report/get_student_quiz_response`,
    getAssignmentViewSummary: `/Report/get_assignment_view_summary`,
    getAssignmentResourceDetails: `/Report/get_assignment_resources_details`,
    exportCsv: `/Report/export_csv`,
    // new Reports api
    getLatestAssignment: `/Report/get_latest_assignment`,
    getAssignmentProgress: `/Report/get_assignment_progress`,
    getStudentAssignments: `/Report/get_student_assignments`,
    getAssignmentsByUser: `/Report/get_assignments_by_user`,
    getAssignmentsByLesson: `/Report/get_assignment_by_lesson`,
    getItemwisewQuiz: `/Report/get_itemwise_quiz`,
    getGroupAssessmentReport: `/Report/get_group_assessment_report`,
    getStudentAssessmentReport: `/Report/get_student_assessment_report`,
    exportGroupReportCsv: `/Report/export_group_report_csv`,
    exportStudentReportCsv: `/Report/export_student_report_csv`,
    getIndividualAssessmentReport: `/Report/get_individual_assessment_report`,
    getIndividualStudentAssessmentReport: `/Report/get_individual_student_assessment_report`,
    exportIndividualAssesmentReportCsv: `/Report/export_individual_assessment_report_csv`,
    exportIndividualStudentQuizReportCsv: `/Report/export_individual_student_quiz_report_csv`,
    exportIndividualStudentAssessmentReportCsv: `/Report/export_individual_student_assesment_report_csv`,
    getIndividualQuizResponse: `/Report/get_individual_quiz_response`,
    getIndividualAssessmentResponse: `/Report/get_individual_assessment_response`,
    getIndividualQuizAttempts: `/Report/get_individual_quiz_attempts`,
    getIndividualAssessmentAttempts: `/Report/get_individual_assessment_attempts`,
    getIndividualLearnosityAssessmentAttempts: `/Report/get_individual_learnosity_assessment_attempts`,
    exportItemAnalysisReport: `/Report/export_item_analysis_report`,
    getIndividualLearnosityAssessmentResponse: `/Report/get_individual_learnosity_assessment_response`,
    getIndividualLearnosityAssessmentReport: `/Report/get_individual_learnosity_assessment_report`,
    getLearnosityItemwiseQuiz: `/Report/get_learnosity_itemwise_quiz`,
    learnosityExportItemAnalysisReport: `/Report/learnosity_export_item_analysis_report`,
  },
  preview: {
    getPreviewItemAccessed: `/PreviewDashboard/get_preview_items_accessed`,
    scheduleRequest: `/PreviewDashboard/schedule_request`,
  },
  School: {
    getSchoolMapping: `/School/get_school_mapping`,
    updateSchoolMapping: `/School/update_school_mapping`,
  },
  quizSmart: {
    getCode: `/group/get_code`,
    createCode: `/group/create_code`,
    activateQuiz: `/quizActivation/activate_quiz`,
    updateQuizTimer: `/quizActivation/update_quiz_timer`,
    getQuestion: `/quiz/get_question`,
    showResult: `/quiz/show_results`,
    showReportCard: `/quiz/show_reportcard`,
    showQuizWinnerReportcard: `/quiz/show_quiz_winner_reportcard`,
    exportQuizAssessmentReportCsv: `/quiz/export_quiz_assessment_report_csv`,
    finishQuiz: `/quiz/finish_quiz`,
    deleteGroup: `/group/delete_group`,
  },
  Learnosity: {
    startLearnosityAssessment: `/Learnosity/start_learnosity_assessment`,
    submitLearnosityAssessment: `/Learnosity/submit_learnosity_assessment`,
    getLearnosityScoresSignature: `/Learnosity/get_learnosity_scores_signature`,
    syncLearnosityScore: `/Learnosity/sync_learnosity_score`,
  },
  notification: {
    getAllnotifications: `/notification/get_allnotifications`,
    getNotification: `/notification/get_notification`,
    createNotification: `/notification/create_notification`,
    updateNotification: `/notification/update_notification`,
    disableNotification: `/notification/disable_notification`,
  },
};

export function makeLookupUrl({ entity, pk, isReadAll }) {
  let out = urls.crossing.lookup + entity;
  if (isReadAll) {
    return out + "/readAll";
  } else {
    return out + "/read" + (pk ? `/${pk}` : "");
  }
}

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  return JSON.parse(window.atob(base64));
}

export function post({ url, data, method }) {
  const jwt = sessionStorage.getItem("jwt") || "";
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  };
  headers["Authorization"] = `Bearer ${jwt}`;
  return fetch(url, {
    body: JSON.stringify(data), // must match 'Content-Type' header
    headers,
    method: method || "POST", // *GET, POST, PUT, DELETE, etc.
    referrer: "no-referrer", // *client, no-referrer
  })
    .then((res) => res.json().catch((err) => res))
    .then((res) => {
      if (res.status && res.status !== 200) {
        throw res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      throw e.message || e.statusText || "unknown post error";
    });
}

export function get(url) {
  return fetch(url)
    .then((res) => res.json())
    .catch((e) => {
      console.error("--------api get:", e);
      throw e.message || e.statusText || "unknown get error";
    });
}

export default function withApiService(WrappedComponent) {
  class C extends React.Component {
    render() {
      return (
        <WrappedComponent
          {...this.props}
          apiService={{ post, get, urls, parseJwt }}
        />
      );
    }
  }
  C.displayName = `withApiService(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;
  return C;
}
