import * as React from "react";
import { Persist } from "react-persist";
import { urls } from "HOC/withApiService";
import compose from "utils/compose";
import { routePaths } from "../containers/Routes/Routes";
import withSessionContext from "HOC/withSessionContext";
import withWorkspaceContext from "HOC/withWorkspaceContext";
const qs = require("query-string");
const ContentContext = React.createContext();

export const ContentContextConsumer = ContentContext.Consumer;

let initialState = {
  page: "Content",
  playlistResources: [],
  renderItemData: {},
  unitData: {},
  itemData: {},
  tab: "sciencelibrary",
  playListNames: [],
};
const domParse = (data) => {
  let parser = new DOMParser();
  let dom = parser.parseFromString("<!doctype html><body>" + data, "text/html");
  return dom.body.textContent;
};
class ContentContextProvider extends React.Component {
  state = {
    ...initialState,
    showErrorText: "",
    _setState: (next, cb) => {
      if (typeof next === "function") {
        this.setState(
          (state) => next(state),
          () => {
            cb && typeof cb === "function" && cb(this.state);
            console.log("-------new Content context:", this.state);
          }
        );
      } else {
        this.setState({ ...next }, () => {
          cb && typeof cb === "function" && cb(this.state);
          console.log("-------new Content context:", this.state);
        });
      }
    },

    resetState: () => {
      this.setState(
        (state) => ({
          ...initialState,
          // ContentType: state.ContentType //remember this so when Content is complete, operator only has to choose traveler type
        }),
        () => {
          console.log("--------Content context reset:", this.state);
        }
      );
    },
    getClasses: async (assessment_id) => {
      const { post, userData } = this.props.sessionContext;
      const payload = {
        user_puid: userData.userPuid,
        lesson_id: assessment_id,
      };

      let res = await post({
        url: `${urls.lesson.getClasses}?${qs.stringify(payload)}`,
        method: "GET",
        data: undefined,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            classData: res.data,
          }));
          return res.data;
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          // throw res.message;
        }
      } else {
        throw "Delete Lesson Error";
      }
    },
    getStudentGroups: async (groupid, assessment_id) => {
      const { post, userData } = this.props.sessionContext;
      const payload = {
        user_puid: userData.userPuid,
        group_puid: groupid,
        lesson_id: assessment_id,
      };

      let res = await post({
        method: "GET",
        url: `${urls.mygroups.getClassesAndUsers}?${qs.stringify(payload)}`,
        data: undefined,
      });

      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            studentGroupData: res.data,
          }));
          return res.data;
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Delete Lesson Error";
      }
    },
    getContentData: async () => {
      const { post, userData } = this.props.sessionContext;
      this.state._setState((state) => ({
        ...state,
        unitData: {},
      }));

      let res = await post({
        method: "GET",
        // url: `${urls.content.getUnits}?user_puid=${userData.userPuid}`,
        url: `${urls.content.getUnits}?user_puid=${userData.userPuid}`,
        // 4aed5926-115a-424d-b19d-bae63f850ec3
        data: undefined,
      });
      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          // if (
          //   this.props.sessionContext.contentGrade &&
          //   this.props.sessionContext.contentGrade.product_id
          // ) {
          //   this.state._setState((state) => ({
          //     ...state,
          //     unitData: {
          //       ...res.data,
          //       default_data: {
          //         ...res.data.default_data,
          //         product_id:
          //           this.props.sessionContext.contentGrade &&
          //           this.props.sessionContext.contentGrade.product_id,
          //       },
          //     },
          //   }));
          // } else {
          this.state._setState((state) => ({
            ...state,
            unitData: res.data,
          }));
          return res.data;
          // }
        } else {
          throw res.error;
        }
      } else {
        throw "Content error";
      }
    },
    getContentURL: async (data) => {
      const { post, userData } = this.props.sessionContext;
      let payload = {
        ...data,
        user_puid: userData.userPuid,
      };
      let res = await post({
        url: `${urls.content.getContentUrlId}`,
        contentType: "application/json",
        isUrlEncoded: true,
        data: payload,
      });
      if (res) {
        // res = JSON.parse(res);

        if (res.status === "success") {
          return res.data;
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
          throw res.error;
        }
      } else {
        throw "Content URL error";
      }
    },
    getContentItems: async (data = {}, engProductId) => {
      try {
        this.props.sessionContext.startSpinner();
        const { post, userData } = this.props.sessionContext;
        this.state._setState((state) => ({
          ...state,
          itemData: {},
          renderItemData: {},
        }));
        if (Object.values(data).length === 0) {
          data = {
            product_id: this.state.unitData.default_data.product_id || "null",
            unit_id: this.state.unitData.default_data.unit_id || "null",
            subunit_id: this.state.unitData.default_data.sub_unit_id || "null",
            level3_id: this.state.unitData.default_data.level3_id || "null",
          };
        }
        let prodList = this.state.unitData?.data;
        let prodData = prodList?.find((d) => d.id == data.product_id);
        data.user_puid = userData.userPuid;
        data.is_learnosity_enabled = prodData?.is_learnosity_enabled || false;

        let res = await post({
          method: "GET",
          url: `${urls.content.getItems}?${qs.stringify(data)}`,
          data: undefined,
        });

        if (res) {
          if (res.status === "success") {
            let isLevel3 = false;

            this.state._setState((state) => ({
              ...state,
              itemData: res.data,
              renderItemData: res.data,
              isLearnosityEnabled: prodData?.is_learnosity_enabled,
              staarItemData:
                !this.state.unitData.default_data?.unit_id && res.data,
            }));

            if (
              res.data.categories[0].categories[0] &&
              res.data.categories[0].categories[0].categories
            ) {
              isLevel3 = true;
            } else {
              isLevel3 = false;
            }
            this.state._setState((state) => ({
              ...state,
              isLevel3,
            }));
            this.props.sessionContext._setState((state) => ({
              //TODO remove later when state(tx or fl) is available in login API
              ...state,
              isLevel3,
            }));
            let selectedPrId = data.product_id;
            if (
              !engProductId &&
              this.state.unitData.default_data?.language_id == 102
            ) {
              let mappingObj =
                this.state.unitData.data.find((p) => p.id == data.product_id) ||
                {};
              selectedPrId = mappingObj.mapped_id || mappingObj.id;
            } else {
              selectedPrId = data.product_id;
            }
            this.props.sessionContext._setState((state) => ({
              ...state,
              contentGrade: {
                product_id: engProductId ? engProductId : selectedPrId,
              },
            }));
          } else {
            this.state._setState((state) => ({
              ...state,
              itemData: {},
              renderItemData: {},
            }));
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
          }
        } else {
          this.state._setState((state) => ({
            ...state,
            itemData: {},
            renderItemData: {},
          }));
          this.props.sessionContext.showNotification({
            message: "Please refresh the page",
            type: "error",
          });
          throw "Content error";
        }
      } catch (err) {
        this.state._setState((state) => ({
          ...state,
          itemData: {},
          renderItemData: {},
        }));
        throw err;
      } finally {
        // this.state._setState((state) => ({
        //   ...state,
        //   loading: false,
        // }));
        this.props.sessionContext.stopSpinner();
      }
    },
    getStaarContentItems: async (data = {}) => {
      try {
        const { post, userData } = this.props.sessionContext;

        let prodList = this.state.unitData?.data;
        let prodData = prodList?.find((d) => d.id == data.product_id);
        data.user_puid = userData.userPuid;
        data.is_learnosity_enabled = prodData?.is_learnosity_enabled || false;

        let res = await post({
          method: "GET",
          url: `${urls.content.getItems}?${qs.stringify(data)}`,
          data: undefined,
        });

        if (res) {
          if (res.status === "success") {
            let isLevel3 = false;

            this.state._setState((state) => ({
              ...state,
              isLearnosityEnabled: prodData?.is_learnosity_enabled,
              staarItemData: res.data,
              renderItemData: res.data,
              showLearnoMessage: true,
            }));
            if (res.data.categories[0].categories[0].categories) {
              isLevel3 = true;
            } else {
              isLevel3 = false;
            }
            this.state._setState((state) => ({
              ...state,
              isLevel3,
            }));
            this.props.sessionContext._setState((state) => ({
              //TODO remove later when state(tx or fl) is available in login API
              ...state,
              isLevel3,
            }));
          } else {
            this.state._setState((state) => ({
              ...state,
              staarItemData: {},
            }));
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
          }
        } else {
          this.state._setState((state) => ({
            ...state,
            staarItemData: {},
          }));
          this.props.sessionContext.showNotification({
            message: "Please refresh the page",
            type: "error",
          });
          throw "Content error";
        }
      } catch (err) {
        this.state._setState((state) => ({
          ...state,
          itemData: {},
          renderItemData: {},
        }));
        throw err;
      } finally {
        // this.state._setState((state) => ({
        //   ...state,
        //   loading: false,
        // }));
      }
    },
    assignAssessment: async (data, isTeacherDashboard) => {
      const { post, userData } = this.props.sessionContext;
      const payload = {
        user_puid: userData.userPuid,
        lesson_id: data.lesson_id,
        group_puid: data.group_puid,
        assignment_no: data.assignment_no,
        assignment_details: JSON.stringify(data.assignment_details),
      };
      let res = await post({
        contentType: "application/json",
        isUrlEncoded: true,
        url: urls.lesson.assignAssignment,
        data: payload,
      });

      if (res) {
        if (res.status === "success") {
          if (!isTeacherDashboard) {
            await this.state.getContentItems();
          }

          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Assign Assignment Error";
      }
    },
    assignGroups: async (data, isTeacherDashboard) => {
      const { post, userData } = this.props.sessionContext;
      const payload = {
        user_puid: userData.userPuid,
        ...data,
      };
      let res = await post({
        contentType: "application/json",
        isUrlEncoded: true,
        url: urls.lesson.assignMultipleClasses,
        data: payload,
      });

      if (res) {
        if (res.status === "success") {
          if (!isTeacherDashboard) {
            await this.state.getContentItems();
          }

          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Assign Assignment Error";
      }
    },
    createLesson: (product_id, type, history, playListNames) => {
      this.props.sessionContext.startSpinner();
      this.props.WorkspaceContext._setState(
        (state) => ({
          ...state,
          playListNames: playListNames,
          lessonUpdateData: {
            ...state.lessonUpdateData,
            resources: this.state.playlistResources.sort(
              (a, b) => a.sort_order - b.sort_order
            ),
            product_id: product_id,
            unit_id: this.state.unitData.default_data.unit_id,
          },
        }),
        async () => {
          try {
            this.state._setState((state) => ({
              ...state,
              // playlistResources:[],
              // playListNames:[]
            }));
            await this.props.WorkspaceContext.getWorkspaceData();
            if (type === "lesson") {
              this.props.sessionContext.stopSpinner();
              history.push(routePaths.workspace.addNewLesson + "/lesson/new");
            } else {
              this.props.sessionContext.stopSpinner();
              history.push(
                routePaths.workspace.addNewLesson + "/assignment/new"
              );
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.props.sessionContext.stopSpinner();
          }
        }
      );
    },
    unAssign: async (data) => {
      const { post } = this.props.sessionContext;

      let res = await post({
        contentType: "application/json",
        isUrlEncoded: true,
        url: urls.lesson.unAssign,
        data: data,
      });

      if (res) {
        if (res.status === "success") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "success",
          });
        } else {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
          throw res.message;
        }
      } else {
        throw "Assign Assignment Error";
      }
    },
    getContent: async (id) => {
      const { post } = this.props.sessionContext;
      let data = {
        id: id,
      };

      let res = await post({
        method: "GET",

        url: `${urls.content.getContent}?${qs.stringify(data)}`,
        data: undefined,
      });
      if (res) {
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            conentURLData: res.data,
          }));
          return res.data;
        }
      } else {
        throw "err";
      }
    },
    assessmentOverview: async (assessment_id) => {
      const { post, userData } = this.props.sessionContext;
      let data = {
        user_puid: userData.userPuid,
        assessment_id: assessment_id,
        lesson_resource_tracking_id: this.state.contentTrackId,
        lesson_id: 0,
      };

      let res = await post({
        method: "GET",
        url: `${urls.assessment.assessment_overview}?${qs.stringify(data)}`,
        data: undefined,
      });
      if (res) {
        // res = JSON.parse(res);
        if (res.status === "success") {
          // this.props.sessionContext.showNotification({
          //   message: "success",
          //   type: "success",
          // });
          return res;
        } else if (res.status === "error") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
        } else {
          throw "error";
        }
      }
    },
    startAssessment: async (data, isLeanosity) => {
      const { post, userData } = this.props.sessionContext;
      const quizId = data;

      try {
        let res = await post({
          url: `${urls.assessment.start_assessment}?assessment_id=${quizId}&user_puid=${userData.userPuid}&lesson_resource_tracking_id=${this.state.contentTrackId}&lesson_id=0&is_learnosity_enabled=${isLeanosity}`,
        });

        if (res) {
          if (res.status === "success") {
            this.state._setState((state) => ({
              ...state,
              startAssessmentData: res,
            }));
          } else {
            throw res.message;
          }
        } else {
          throw "Start Assessment error";
        }
      } catch (e) {
        console.log(e);
        throw "Start Assessment error";
      }
    },
    getPreviewItemAccessed: async (assessment_id) => {
      const { post, userData } = this.props.sessionContext;
      let data = {
        user_puid: userData.userPuid,
      };

      let res = await post({
        method: "GET",
        url: `${urls.preview.getPreviewItemAccessed}?${qs.stringify(data)}`,
        data: undefined,
      });
      if (res) {
        // res = JSON.parse(res);
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            previewItemData: res.data,
          }));
          return res;
        } else if (res.status === "error") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
        } else {
          throw "error";
        }
      }
    },
    addExternalResource: async (data, isEdit) => {
      const { post, userData } = this.props.sessionContext;
      data.user_puid = userData.userPuid;
      let updateURL = "";
      if (isEdit) {
        updateURL = urls.content.updateExternalItems;
      } else {
        updateURL = urls.content.addExternalItems;
      }

      let res = await post({
        method: "POST",
        url: `${updateURL}?${qs.stringify(data)}`,
      });
      if (res) {
        // res = JSON.parse(res);
        if (res.status === "success") {
          this.state.getContentItems();
          this.props.sessionContext.showNotification({
            message: "success",
            type: "success",
          });
          return res.status;
        } else if (res.status === "error") {
          this.state._setState((state) => ({
            ...state,
            showErrorText: res.message,
          }));
        } else {
          throw "error";
        }
      }
    },
    addExternalAttachment: async (data) => {
      const { fileUpload } = this.props.sessionContext;

      let res = await fileUpload({
        url: `${urls.content.addExternalAttachment}`,
        data: data,
      });
      if (res) {
        // res = JSON.parse(res);
        if (res.status === "success") {
          this.state.getContentItems();
          this.props.sessionContext.showNotification({
            message: "Success",
            type: "success",
          });
          return res.status;
        } else if (res.status === "error") {
          this.props.sessionContext.showNotification({
            message: res.message,
            type: "error",
          });
        } else {
          throw "error";
        }
      }
    },
    addOrRemoveToMyLibrary: async (data, staarAssessment) => {
      const { post, userData } = this.props.sessionContext;
      let payload = {
        user_puid: userData.userPuid,
        product_id: data.product_id,
        unit_id: data.unit_id,
        subunit_id: data.sub_unit_id,
        level3_id: data.isLevel3 || "null",
        item_id: data.resource_id,
        item_type_id: data.itemTypeId,
      };
      let res;
      if (data.task) {
        res = await post({
          method: "POST",
          url: `${urls.content.addItemsToMyLibrary}?${qs.stringify(payload)}`,
        });

        if (res) {
          if (res.status === "success") {
            if (staarAssessment) {
              const sendData = {
                product_id: this.state.unitData.default_data.product_id,
                unit_id: "null",
                subunit_id: "null",
                level3_id: "null",
              };

              await this.state.getStaarContentItems(sendData);
            }
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "success",
            });
          } else {
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
          }
          this.state._setState({
            ...this.state,
            previewData: {
              ...this.state.previewData,
              mylibrary: res.status === "success",
            },
          });
          await this.state.getContentItems();
        }
      } else {
        if (data.external_resource) {
          let removePayload = {
            user_puid: userData.userPuid,
            resource_id: data.resource_id,
          };
          res = await post({
            method: "DELETE",
            url: `${
              urls.content.removeExternalItemFromMylibrary
            }?${qs.stringify(removePayload)}`,
          });
        } else {
          res = await post({
            method: "DELETE",
            url: `${urls.content.removeItemFromMylibrary}?${qs.stringify(
              payload
            )}`,
          });
        }

        if (res) {
          if (res.status == "success") {
            await this.state.getContentItems();
            if (staarAssessment) {
              const sendData = {
                product_id: this.state.unitData.default_data.product_id,
                unit_id: "null",
                subunit_id: "null",
                level3_id: "null",
              };

              await this.state.getStaarContentItems(sendData);
            }
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "success",
            });
          } else {
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
          }
        }
      }
    },
    trackContent: async (data, type) => {
      const { post, userData } = this.props.sessionContext;
      let res;

      data = { ...data, user_puid: userData.userPuid };
      if (type === "start") {
        res = await post({
          method: "POST",
          url: `${urls.content.trackContentStart}?${qs.stringify(data)}`,
        });
      } else {
        res = await post({
          method: "POST",
          url: `${urls.content.trackContentEnd}?${qs.stringify(data)}`,
        });
      }

      if (res) {
        // res = JSON.parse(res);
        if (res.status === "success") {
          this.state._setState((state) => ({
            ...state,
            contentTrackId: res.data,
          }));
        }
      }
    },
    createLessonInBackground: async (data) => {
      const { fileUpload, userData } = this.props.sessionContext;
      const { unitData } = this.state;
      let selectedUnit = { ...unitData.default_data };
      if (selectedUnit?.language_id == 102) {
        let gId = unitData?.product_mapping?.find(
          (prod) => prod.spanish_id == selectedUnit?.product_id
        )?.english_id;
        selectedUnit.product_id = gId;
        selectedUnit.unit_id = "null";
        selectedUnit.sub_unit_id = "null";
        selectedUnit.level3_id = "null";
      }
      let lexile = "";
      if (data.type_id == 25) {
        for (var prop in data.url_link) {
          if (data.url_link.hasOwnProperty(prop)) {
            if (data.url_link[prop] === data.url_link.url)
              if (prop === "Lexile2_url") {
                lexile = data.url_link.Lexile2_score;
              } else if (prop === "Lexile1_url") {
                lexile = data.url_link.Lexile1_score;
              } else if (prop === "Lexile3_url") {
                lexile = data.url_link.Lexile3_score;
              } else if (prop === "Spanish_url") {
                lexile = "spanish";
              }
          }
        }
      }
      let newRes = [];
      let resource = {
        item_id: data.id,
        resource_type_id: data.type_id,
        time: 0,
        sort_order: 0,
        remarks: lexile,
      };
      newRes.push(resource);
      let tempData = {
        user_puid: userData.userPuid,
        lesson_name: data.name,
        lesson_objective: data.description,
        instructions: null,
        total_time: 15,
        due_date: "2020-07-31 00:00:00",
        iscustom_unit_folder: selectedUnit.unit_id ? "no" : null,
        product_id: selectedUnit.product_id,
        unit_id: selectedUnit.unit_id,
        iscustom_subunit_folder:
          selectedUnit.sub_unit_id || selectedUnit.subunit_id ? "no" : null,
        subunit_id: selectedUnit.sub_unit_id || selectedUnit.subunit_id || null,
        iscustom_level3_folder: selectedUnit.level3_id ? "no" : null,
        level3_id: selectedUnit.level3_id,
        lesson_resources: JSON.stringify(newRes),
        is_assignment: true,
      };

      let formData = new FormData();
      formData.append("user_puid", tempData.user_puid);
      formData.append("lesson_name", tempData.lesson_name);
      formData.append("lesson_objective", tempData.lesson_objective);
      formData.append("total_time", tempData.total_time);
      formData.append("due_date", tempData.due_date);
      formData.append("instructions", tempData.instructions);
      formData.append("iscustom_unit_folder", tempData.iscustom_unit_folder);
      formData.append("product_id", tempData.product_id);
      formData.append("unit_id", tempData.unit_id);
      formData.append(
        "iscustom_subunit_folder",
        tempData.iscustom_subunit_folder
      );
      formData.append("subunit_id", tempData.subunit_id);
      formData.append(
        "iscustom_level3_folder",
        tempData.iscustom_level3_folder
      );
      formData.append("level3_id", tempData.level3_id);
      formData.append("lesson_resources", tempData.lesson_resources);
      formData.append("is_assignment", "yes");
      formData.append("enforce_order", false);

      let res = await fileUpload({
        url: `${urls.lesson.createLesson}`,
        data: formData,
      });

      if (res.data) {
        return res.data;
      }
      return null;
    },
    request: async (payload) => {
      const { post } = this.props.sessionContext;

      payload.grade_levels = JSON.stringify(payload.grade_levels);
      payload.primary_use = JSON.stringify(payload.primary_use);

      try {
        let res = await post({
          url: `${urls.preview.scheduleRequest}?${qs.stringify(payload)}`,
        });

        if (res) {
          if (res.status === "success") {
            this.props.sessionContext.showNotification({
              type: "success",
              message: res.message,
            });
          } else {
            // throw res.message;
          }
        } else {
          throw "Dashboard error";
        }
      } catch (e) {
        console.log(e);
      }
    },
    startLearnosityAssessment: async (data) => {
      const { post } = this.props.sessionContext;
      try {
        let res = await post({
          method: "POST",
          url: `${urls.Learnosity.startLearnosityAssessment}`,
          contentType: "application/json",
          isUrlEncoded: true,
          data: data,
        });

        if (res) {
          if (res.status === "success") {
            return res.data;
          } else {
            this.props.sessionContext.showNotification({
              message: res.message,
              type: "error",
            });
            throw res.message;
          }
        } else {
          throw "Learnosity error";
        }
      } catch (e) {
        console.log(e);
      }
    },
  };

  render() {
    return (
      <ContentContext.Provider value={this.state}>
        {this.props.children}
        <Persist
          name="Content-state"
          data={this.state}
          debounce={500}
          onMount={(data) => this.setState(data)}
        />
      </ContentContext.Provider>
    );
  }
}

export default compose(
  withWorkspaceContext,
  withSessionContext
)(ContentContextProvider);
